import { KTCard } from "_metronic/helpers";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import { searchParams } from "app/helpers/commonInterface";
import { ACTIVITY_STATUS } from "app/helpers/constants";
import { convert } from "app/helpers/helperFunction";
import {
  getAssigneedActivityToUser,
  updateActivity,
} from "app/reducers/activity/activityAction";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import ActivityUpdateForm from "./ActivityUpdateForm";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";

export interface activityRecords {
  Id: number;
  IsActive: boolean;
  Name: string;
  AssigneeId: string;
  PersonAssigned: string;
  CreatorId: string;
  CreatedBy: string;
  Description: string;
  Remark: string;
  CreatedDate: string;
  Status: number;
}

interface props {
  getActivityList: Function;
  updateActivityDispatch: Function;
  activityDetails: {
    loading: boolean;
    error: string;
    articleActivity: {
      data: { records: activityRecords[] };
      success: boolean;
      status: number;
      message: string;
      pagination: {
        page_number: number;
        page_size: number;
        total_record_count: number;
      };
    };
  };
}

const ACTIVITY_STATUS_OPTION = [
  { value: ACTIVITY_STATUS.Assigned, name: "Assigned" },
  { value: ACTIVITY_STATUS.Completed, name: "Completed" },
];

const activityValidationSchema = object().shape({
  Remark: string().required("Remark is required"),
});

const AssignedActivityList: FC<props> = ({
  getActivityList,
  activityDetails,
  updateActivityDispatch,
}) => {
  const [showEditForm, setShowEditForm] = useState({ show: false, id: 0 });

  useEffect(() => {
    getActivityList({ Paginate: false });
  }, []);

  return (
    <>
      <div className="col-12 col-lg-12 col-lg-6 col-md-5 col-xl-5 col-xxl-4 mb-8">
        <KTCard>
          <div className="card-header">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Activity</span>
            </h3>
          </div>
          {activityDetails.loading ? (
            <div className="p-5">
              <Loader />
            </div>
          ) : activityDetails.error ? (
            <ErrorDisplay error={activityDetails.error} />
          ) : (
            <>
              {!activityDetails.articleActivity?.data?.records.length && (
                <div className="fs-5 fw-semibold p-5 text-center">
                  No Records Found
                </div>
              )}
              {activityDetails.articleActivity?.data?.records.length > 0 && (
                <div className="card-body pt-5">
                  <div className="mh-250px overflow-auto vertical-scroll">
                    {activityDetails.articleActivity?.data?.records?.map(
                      (activity: activityRecords, index: number) => {
                        return (
                          <Formik
                            key={index}
                            enableReinitialize
                            initialValues={activity}
                            validationSchema={activityValidationSchema}
                            onSubmit={async (values) => {
                              //console.log(values, "VALUES ACTIVITY");
                              await updateActivityDispatch(values, () => {
                                setShowEditForm({ show: false, id: 0 });
                              });
                            }}
                          >
                            {({
                              values,
                              touched,
                              errors,
                              isSubmitting,
                              setFieldValue,
                            }) => {
                              return (
                                <Form noValidate className="form">
                                  <div
                                    className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#activity-${index}`}
                                  >
                                    <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                      <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            opacity="0.3"
                                            x="2"
                                            y="2"
                                            width="20"
                                            height="20"
                                            rx="5"
                                            fill="currentColor"
                                          />
                                          <rect
                                            x="6.0104"
                                            y="10.9247"
                                            width="12"
                                            height="2"
                                            rx="1"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>

                                      <span className="svg-icon toggle-off svg-icon-1">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            opacity="0.3"
                                            x="2"
                                            y="2"
                                            width="20"
                                            height="20"
                                            rx="5"
                                            fill="currentColor"
                                          />
                                          <rect
                                            x="10.8891"
                                            y="17.8033"
                                            width="12"
                                            height="2"
                                            rx="1"
                                            transform="rotate(-90 10.8891 17.8033)"
                                            fill="currentColor"
                                          />
                                          <rect
                                            x="6.01041"
                                            y="10.9247"
                                            width="12"
                                            height="2"
                                            rx="1"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </div>

                                    <div className="align-items-center d-flex flex-wrap gap-3 justify-content-between w-100">
                                      <div className="text-gray-700 fw-semibold cursor-pointer mb-0">
                                        {activity.Name}
                                      </div>
                                      <div className="d-flex align-items-center gap-5">
                                        <div className="">
                                          <span
                                            className={`badge badge-sm badge-light-${
                                              ACTIVITY_STATUS[
                                                activity.Status
                                              ] === "Assigned"
                                                ? "primary"
                                                : "success"
                                            }`}
                                          >
                                            {ACTIVITY_STATUS[activity.Status]}
                                          </span>
                                        </div>

                                        <div className="text-gray-700 fw-semibold mb-0">
                                          Date : {convert(activity.CreatedDate)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    id={`activity-${index}`}
                                    className="collapse fs-6 ms-1"
                                  >
                                    <div className="d-flex flex-wrap-reverse flex-xxl-nowrap justify-content-between">
                                      <div className="w-100 w-xxl-50">
                                        <div className="mb-4 align-items-center">
                                          <div className="fw-semibold text-gray-600">
                                            Description
                                          </div>
                                          <div className="">
                                            {activity.Description}
                                          </div>
                                        </div>
                                        <div className="mb-4 align-items-center">
                                          <div className="fw-semibold text-gray-600 required">
                                            Remark
                                          </div>
                                          {showEditForm.show &&
                                          showEditForm.id == activity.Id ? (
                                            <div>
                                              <Field
                                                className={`form-control form-control-md form-control-solid w-sm-75 w-lg-100 w-xl-75  ${
                                                  touched.Remark &&
                                                  errors.Remark &&
                                                  "is-invalid inValidBorder"
                                                }`}
                                                placeholder="Remark"
                                                id="Remark"
                                                name="Remark"
                                                onChange={(
                                                  e: ChangeEvent<HTMLTextAreaElement>
                                                ) =>
                                                  setFieldValue(
                                                    "Remark",
                                                    e.target.value
                                                  )
                                                }
                                                value={values.Remark}
                                                disabled={isSubmitting}
                                              />
                                            </div>
                                          ) : (
                                            <div className="">
                                              {activity.Remark}
                                            </div>
                                          )}
                                        </div>
                                        <div className="mb-4 align-items-center">
                                          <div className="fw-semibold text-gray-600">
                                            CreatedBy
                                          </div>
                                          <div className="">
                                            {activity.CreatedBy}
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        {showEditForm.show &&
                                        showEditForm.id == activity.Id ? (
                                          <div className="d-flex flex-sm-nowrap flex-wrap flex-xl-wrap gap-3 mb-3 mb-xxl-0">
                                            <div className="w-125px">
                                              <DropDown
                                                className="text-start form-control form-control-sm py-0 px-4 form-control-solid form-select"
                                                placeholder="Select Status"
                                                options={ACTIVITY_STATUS_OPTION}
                                                setFieldValue={setFieldValue}
                                                name="Status"
                                                defaultValue={{
                                                  value: values.Status,
                                                  name: ACTIVITY_STATUS[
                                                    values.Status
                                                  ],
                                                }}
                                              />
                                            </div>
                                            {/* <div className="d-flex gap-3"> */}
                                            <ResetButton
                                              className="btn btn-sm btn-light"
                                              name="Cancel"
                                              disabled={isSubmitting}
                                              onClickCallback={() =>
                                                setShowEditForm({
                                                  show: false,
                                                  id: 0,
                                                })
                                              }
                                            />
                                            <SubmitButton
                                              isSubmitting={isSubmitting}
                                              name="Submit"
                                              className="btn btn-primary btn-sm"
                                            />
                                            {/* </div> */}
                                          </div>
                                        ) : (
                                          <div className="mb-3 mb-xxl-0">
                                            <button
                                              className="btn btn-sm btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setShowEditForm({
                                                  show: true,
                                                  id: activity.Id,
                                                })
                                              }
                                            >
                                              Edit Activity
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="separator separator-dashed"></div>
                                </Form>
                              );
                            }}
                          </Formik>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </KTCard>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activityDetails: state.articleActivity,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivityList: (searchObj: searchParams) =>
      dispatch(getAssigneedActivityToUser(searchObj)),
    updateActivityDispatch: (updatedActivity: any, successCallback: Function) =>
      dispatch(updateActivity(updatedActivity, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedActivityList);
