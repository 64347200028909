import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ScrollTopComponent,
  DrawerComponent,
  ToggleComponent,
  StickyComponent,
} from "../../../assets/ts/components";
import { KTSVG } from "../../../helpers";
import { multiply } from "lodash";
import TaskChat from "app/components/pages/main/admin/taskDetail/chat/TaskChat";

export const Chat = () => {
  const [openChat, setOpenChat] = useState(false);
  const [chatId, setChatId] = useState(0);
  return (
    <div>
      <div
        id=""
        className="btn btn-icon btn-active-light-primary btn-custom chat"
        data-kt-scrolltop=""
        onClick={() => {
          setOpenChat(!openChat);
          //setChatId(Number(params.id));
        }}
      >
        <KTSVG
          path="/media/icons/duotune/communication/com007.svg"
          className="theme-light-hide svg-icon-2"
        />
      </div>
      {/* {chatId != 0 && ( */}
        <TaskChat
          //id={chatId.toString()}
          setOpenChat={setOpenChat}
          isDrawer={true}
          openChat={openChat}
        />
      {/* )} */}
    </div>
  );
};
