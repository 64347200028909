import {
  MASTER_DATA_FAILURE,
  MASTER_DATA_REQUEST,
  MASTER_DATA_SUCCESS,
} from "../actionTypes";

interface generalModal {
  Name: string;
  Id: number;
}

interface userModal extends generalModal {
  FirstName: string;
  LastName: string;
  Email: string;
}

interface billModal extends generalModal {
  BillNumber: string;
  ClientId: number;
  ClientName: string;
  Date: string;
  PaidAmount: number;
  TotalAmount: number;
}

interface reimbursementModal extends generalModal {
  BillNumber: string;
  ClientId: number;
  ClientName: string;
  PaidAmount: number;
  TotalAmount: number;
}

interface taskModal extends generalModal {
  ClientId: number;
  ClientName: string;
  TaskCode: string;
  TaskTypeId: Number;
}

interface documentTypeModal extends generalModal {
  Repeatation: number;
}

interface taskTypeActivityModal {
  CompletionFieldName: string;
  CompletionFieldType: string;
  Id: number;
  IsMandatory: boolean;
  MinValue: number;
  Name: string;
  RequireChecking: boolean;
  RequireCompletionField: boolean;
}

interface firmModel {
  Books: {
    Name: string;
    IsCashBook: boolean;
    FirmId: number;
    Id: number;
    IsActive: boolean;
  }[];
  Id: number;
  IsActive: boolean;
  Name: string;
}
interface categoryTaskTypeModal {
  Id: number;
  Name: string;
  DefaultAssigneeId: string;
  DefaultAssigneeName: string;
  DefaultExpertId: string;
  DefaultExpertName: string;
  DefaultManagerId: string;
  DefaultManagerName: string;
  RequireConfirmation: true;
  StandardFees: number;
  IsRepeatative: boolean;
  IdealDaysToComplete: number;
  GovernmentFixDate: string;
  Activities: Array<taskTypeActivityModal>;
  OnboardBefore: number;
}

interface categoryModal extends generalModal {
  TaskTypes: Array<categoryTaskTypeModal>;
}

interface masterDataRecordsModal {
  Categories: categoryModal[];
  Groups: generalModal[];
  TaskTypes: generalModal[];
  Clients: generalModal[];
  Firms: firmModel[];
  Expenses: generalModal[];
  Bills: billModal[];
  Tasks: taskModal[];
  DocumentTypes: documentTypeModal[];
  // Users: {
  //   Admin: userModal[]
  //   TeamLeader: userModal[]
  //   Article: userModal[]
  // };
  Admin: userModal[];
  TeamLeader: userModal[];
  Article: userModal[];
  Reimbursements: reimbursementModal[];
}

export interface masterDataModal {
  success: boolean;
  data: { records: masterDataRecordsModal };
  message: string;
  status: number;
}

export interface masterDataState {
  loading: boolean;
  masterData: masterDataModal | {};
  error: string;
}

interface changeDataModal {
  masterType: string;
  data: any;
}

interface action {
  type: string;
  payload: masterDataModal | string | changeDataModal;
}

const initialState: masterDataState = {
  loading: false,
  masterData: {},
  error: "",
};

export const ADD_DATA_IN_MASTER = "addData";
export const UPDATE_DATA_IN_MASTER = "updateData";
export const UPDATE_DATA_STATUS_IN_MASTER = "updateDataStatus";

export enum MASTER_TYPE {
  ARTICLE = "Article",
  TEAM_LEADER = "TeamLeader",
  ADMIN = "Admin",
  DOCUMENT_TYPES = "DocumentTypes",
  TASKS = "Tasks",
  BILLS = "Bills",
  EXPENSES = "Expenses",
  FIRMS = "Firms",
  CLIENTS = "Clients",
  TASK_TYPES = "TaskTypes",
  GROUPS = "Groups",
  CATEGORIES = "Categories",
  BOOKS = "Books",
}

export const getMasterDataReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case MASTER_DATA_REQUEST:
      return {
        loading: true,
      };
    case MASTER_DATA_SUCCESS:
      return {
        loading: false,
        masterData: {
          ...(action.payload as masterDataModal),
          data: {
            ...(action.payload as masterDataModal).data,
            records: {
              ...(action.payload as masterDataModal).data.records,
              Admin: (action.payload as any).data.records.Users.Admin,
              Article: (action.payload as any).data.records.Users.Article,
              TeamLeader: (action.payload as any).data.records.Users.TeamLeader,
            },
          },
        },
        error: "",
      };
    case MASTER_DATA_FAILURE:
      return {
        loading: false,
        masterData: {},
        error: action.payload,
      };
    case ADD_DATA_IN_MASTER:
      if (
        "data" in state.masterData &&
        (action.payload as changeDataModal).masterType === MASTER_TYPE.TASKS
      ) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [(action.payload as changeDataModal).masterType]: [
                  ...state.masterData.data.records[
                    (action.payload as changeDataModal)
                      .masterType as keyof masterDataRecordsModal
                  ],
                  ...(action.payload as changeDataModal).data,
                ],
              },
            },
          },
        };
      } else if (
        "data" in state.masterData &&
        (action.payload as changeDataModal).masterType ===
          MASTER_TYPE.TASK_TYPES
      ) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [MASTER_TYPE.TASK_TYPES]: [
                  ...state.masterData.data.records[MASTER_TYPE.TASK_TYPES],
                  (action.payload as changeDataModal).data,
                ],
                [MASTER_TYPE.CATEGORIES]: state.masterData.data.records[
                  MASTER_TYPE.CATEGORIES
                ].map((obj: any) => {
                  if (
                    String(obj.Id) ===
                    String((action.payload as changeDataModal).data.CategoryId)
                  ) {
                    return {
                      ...obj,
                      TaskTypes: [
                        ...(obj.TaskTypes || []),
                        (action.payload as changeDataModal).data,
                      ],
                    };
                  }
                  return obj;
                }),
              },
            },
          },
        };
      } else if (
        "data" in state.masterData &&
        (action.payload as changeDataModal).masterType === MASTER_TYPE.BOOKS
      ) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [MASTER_TYPE.FIRMS]: state.masterData.data.records[
                  MASTER_TYPE.FIRMS
                ].map((firm: any) => {
                  if (firm.Id == (action.payload as any).data.FirmId) {
                    return {
                      ...firm,
                      [MASTER_TYPE.BOOKS]: [
                        ...firm[MASTER_TYPE.BOOKS],
                        (action.payload as any).data,
                      ],
                    };
                  }
                  return firm;
                }),
              },
            },
          },
        };
      } else if ("data" in state.masterData) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [(action.payload as changeDataModal).masterType]: [
                  ...state.masterData.data.records[
                    (action.payload as changeDataModal)
                      .masterType as keyof masterDataRecordsModal
                  ],
                  (action.payload as changeDataModal).data,
                ],
              },
            },
          },
        };
      }
      return state;
    case UPDATE_DATA_IN_MASTER:
      if (
        MASTER_TYPE.TASK_TYPES ==
          (action.payload as changeDataModal).masterType &&
        "data" in state.masterData
      ) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...(state.masterData as any).data,
              records: {
                ...(state.masterData as any).data.records,
                [MASTER_TYPE.TASK_TYPES]: state.masterData.data.records[
                  MASTER_TYPE.TASK_TYPES
                ].map((type: any) => {
                  if (
                    String(type.Id) ===
                    String((action.payload as changeDataModal).data.Id)
                  ) {
                    return (action.payload as changeDataModal).data;
                  }
                  return type;
                }),
                [MASTER_TYPE.CATEGORIES]: [
                  ...(state.masterData as any).data.records[
                    MASTER_TYPE.CATEGORIES
                  ].map((data: any) => {
                    if (
                      data.Id ==
                      (action.payload as changeDataModal).data.CategoryId
                    ) {
                      return {
                        ...data,
                        TaskTypes: data.TaskTypes.map((taskTypes: any) => {
                          if (
                            taskTypes.Id ==
                            (action.payload as changeDataModal).data.Id
                          ) {
                            return (action.payload as changeDataModal).data;
                          }
                          return taskTypes;
                        }),
                      };
                    }
                    return data;
                  }),
                ],
              },
            },
          },
        };
      } else if (
        MASTER_TYPE.BOOKS == (action.payload as changeDataModal).masterType &&
        "data" in state.masterData
      ) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [MASTER_TYPE.FIRMS]: state.masterData.data.records[
                  MASTER_TYPE.FIRMS
                ].map((firm: any) => {
                  if (firm.Id == (action.payload as any).data.FirmId) {
                    return {
                      ...firm,
                      [MASTER_TYPE.BOOKS]: firm[MASTER_TYPE.BOOKS].map(
                        (book: any) => {
                          if (book.Id == (action.payload as any).data.Id) {
                            return (action.payload as any).data;
                          }
                          return book;
                        }
                      ),
                    };
                  }
                  return firm;
                }),
              },
            },
          },
        };
      } else if ("data" in state.masterData) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [(action.payload as changeDataModal).masterType]: [
                  ...state.masterData.data.records[
                    (action.payload as changeDataModal)
                      .masterType as keyof masterDataRecordsModal
                  ].filter(
                    (data: any) =>
                      data.Id !== (action.payload as changeDataModal).data.Id
                  ),
                  (action.payload as changeDataModal).data,
                ],
              },
            },
          },
        };
      }
      return state;
    case UPDATE_DATA_STATUS_IN_MASTER:
      if (
        "data" in state.masterData &&
        !(action.payload as changeDataModal).data.IsActive
      ) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [(action.payload as changeDataModal).masterType]:
                  state.masterData.data.records[
                    (action.payload as changeDataModal)
                      .masterType as keyof masterDataRecordsModal
                  ]?.filter(
                    (data: any) =>
                      data.Id !== (action.payload as changeDataModal).data.Id
                  ),
              },
            },
          },
        };
      } else if (
        "data" in state?.masterData &&
        (action?.payload as changeDataModal)?.data?.IsActive
      ) {
        return {
          ...state,
          masterData: {
            ...state.masterData,
            data: {
              ...state.masterData.data,
              records: {
                ...state.masterData.data.records,
                [(action.payload as changeDataModal).masterType]: [
                  ...state.masterData.data.records[
                    (action.payload as changeDataModal)
                      .masterType as keyof masterDataRecordsModal
                  ],
                  (action.payload as changeDataModal).data,
                ],
              },
            },
          },
        };
      }
      return state;
    default:
      return state;
  }
};
