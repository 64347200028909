import { activityData } from "app/components/pages/main/admin/taskDetail/taskDetail/TaskDetails";
import {
  assigneeChangeData,
  statusChangeData,
} from "../../components/pages/main/admin/taskDetail/AdminTaskDetailPage";
import { addTaskValue, dueDateChangeValue } from "../../components/pages/task/TaskForm";
import { searchParams } from "../../helpers/commonInterface";
import { failure, request, success } from "../dispatchFunctions";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import {
  addRemarksService,
  addTaskService,
  changeStatusOfActivityService,
  deleteFileService,
  deleteRemarksService,
  downloadFileService,
  editRemarksService,
  generalfileUploadService,
  getAllRequestForDueDateChangeService,
  getAssigneedTaskToUserService,
  getAssigneeService,
  getBillingPendingTaskListService,
  getChatNotificationTasksService,
  getCompletedTaskReportListService,
  getCreaterService,
  getPlannerTaskService,
  getReviewerRequestsService,
  getTaskListService,
  getTaskReportListService,
  getTaskService,
  getUrgentPlannerTaskService,
  requestDueDateChangeService,
  sendOTPRequestService,
  taskForCheckingService,
  updateBillStatusService,
  updateRequestStatusService,
  updateTaskAssigneeService,
  updateTaskService,
  updateTaskStatusService,
  updateUrgentStatusService,
  uploadFileService,
  uploadFileToRemarks,
} from "./taskService";
import {
  ADD_LOG_FOR_CHANGE_TASK_ASSIGNEE,
  ADD_PARENT_DATA_FOR_SELECTED_LIST,
  ADD_REMARKS_FAILURE,
  ADD_REMARKS_REQUEST,
  ADD_REMARKS_SUCCESS,
  ADD_TASK_FAILURE,
  ADD_TASK_REQUEST,
  ADD_TASK_SUCCESS,
  CHANGE_BILL_STATUS_FAILURE,
  CHANGE_BILL_STATUS_REQUEST,
  CHANGE_BILL_STATUS_SUCCESS,
  CHANGE_TASK_ACTIVITY_STATUS_FAILURE,
  CHANGE_TASK_ACTIVITY_STATUS_REQUEST,
  CHANGE_TASK_ACTIVITY_STATUS_SUCCESS,
  CHANGE_TASK_ASSIGNEE_FAILURE,
  CHANGE_TASK_ASSIGNEE_REQUEST,
  CHANGE_TASK_ASSIGNEE_SUCCESS,
  CHANGE_TASK_STATUS_FAILURE,
  CHANGE_TASK_STATUS_REQUEST,
  CHANGE_TASK_STATUS_SUCCESS,
  CLIENT_FILE_HANDLER,
  CLIENT_MESSAGE_HANDLER,
  DELETE_FILE_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_REMARKS_FAILURE,
  DELETE_REMARKS_REQUEST,
  DELETE_REMARKS_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DUE_DATE_CHANGE_FAILURE,
  DUE_DATE_CHANGE_REQUEST,
  DUE_DATE_CHANGE_SUCCESS,
  EDIT_REMARKS_FAILURE,
  EDIT_REMARKS_REQUEST,
  EDIT_REMARKS_SUCCESS,
  FETCH_REVIEWER_REQUESTS_FAILURE,
  FETCH_REVIEWER_REQUESTS_REQUEST,
  FETCH_REVIEWER_REQUESTS_SUCCESS,
  GENERAL_FILE_UPLOAD_FAILURE,
  GENERAL_FILE_UPLOAD_REQUEST,
  GENERAL_FILE_UPLOAD_SUCCESS,
  GET_ALL_REQUEST_FAILURE,
  GET_ALL_REQUEST_REQUEST,
  GET_ALL_REQUEST_SUCCESS,
  GET_ASSIGNED_TASK_FAILURE,
  GET_ASSIGNED_TASK_REQUEST,
  GET_ASSIGNED_TASK_SUCCESS,
  GET_ASSIGNEE_FAILURE,
  GET_ASSIGNEE_REQUEST,
  GET_ASSIGNEE_SUCCESS,
  GET_BILLING_PENDING_TASK_LIST_FAILURE,
  GET_BILLING_PENDING_TASK_LIST_REQUEST,
  GET_BILLING_PENDING_TASK_LIST_SUCCESS,
  GET_CHATNOTIFICTION_TASK_LIST_FAILURE,
  GET_CHATNOTIFICTION_TASK_LIST_REQUEST,
  GET_CHATNOTIFICTION_TASK_LIST_SUCCESS,
  GET_CHECKING_TASK_FAILURE,
  GET_CHECKING_TASK_REQUEST,
  GET_CHECKING_TASK_SUCCESS,
  GET_COMPLETED_TASK_REPORT_LIST_FAILURE,
  GET_COMPLETED_TASK_REPORT_LIST_REQUEST,
  GET_COMPLETED_TASK_REPORT_LIST_SUCCESS,
  GET_CREATER_FAILURE,
  GET_CREATER_REQUEST,
  GET_CREATER_SUCCESS,
  GET_FOLLOWUP_TASK_FAILURE,
  GET_FOLLOWUP_TASK_REQUEST,
  GET_FOLLOWUP_TASK_SUCCESS,
  GET_PLANNER_TASK_FAILURE,
  GET_PLANNER_TASK_REQUEST,
  GET_PLANNER_TASK_SUCCESS,
  GET_TASK_BY_ID_FAILURE,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_LIST_FAILURE,
  GET_TASK_LIST_REQUEST,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_REPORT_LIST_FAILURE,
  GET_TASK_REPORT_LIST_REQUEST,
  GET_TASK_REPORT_LIST_SUCCESS,
  GET_URGENT_TASK_FAILURE,
  GET_URGENT_TASK_REQUEST,
  GET_URGENT_TASK_SUCCESS,
  SELECT_ALL_TASK_CHECKBOX,
  SELECT_TASK_CHECKBOX,
  SEND_OTP_FAILURE,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SET_URGENT_STATUS_FAILURE,
  SET_URGENT_STATUS_REQUEST,
  SET_URGENT_STATUS_SUCCESS,
  TASK_ADD_FOR_CHECKING_FAILURE,
  TASK_ADD_FOR_CHECKING_REQUEST,
  TASK_ADD_FOR_CHECKING_SUCCESS,
  UNSELECT_ALL_TASK_CHECKBOX,
  UNSELECT_TASK_CHECKBOX,
  UPDATE_REQUEST_STATUS_FAILURE,
  UPDATE_REQUEST_STATUS_REQUEST,
  UPDATE_REQUEST_STATUS_SUCCESS,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_TO_REMARKS_FAILURE,
  UPLOAD_FILE_TO_REMARKS_REQUEST,
  UPLOAD_FILE_TO_REMARKS_SUCCESS,
} from "../actionTypes";
import { uploadFileData } from "app/components/pages/main/admin/taskDetail/taskAttachments/TaskAttachmentsDetails";
import { remarkData } from "app/components/pages/main/admin/taskDetail/remarks/TaskRemarksForm";
import { sentForCheckDataModal } from "app/components/pages/main/admin/taskDetail/taskDetail/TaskDetailHeader";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
} from "../masterData/masterDataReducer";
import { updateMasterData } from "../masterData/masterDataAction";
import { GetErrorMessage } from "../stateHelperFunction";
import { REQUEST_STATUS } from "app/helpers/constants";
import { GET_BILLING_PENDING_TASK } from "app/helpers/config";

export const addTask = (data: addTaskValue, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_TASK_REQUEST));

    await addTaskService(data).then(
      (result: any) => {
        dispatch(success(ADD_TASK_SUCCESS, result));
        showToastMessageSuccess("Task added");
        successCallback();
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.TASKS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(ADD_TASK_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getTaskList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_TASK_LIST_REQUEST));

    getTaskListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_TASK_LIST_SUCCESS, result.data));
        dispatch(success(ADD_PARENT_DATA_FOR_SELECTED_LIST, result.data));
      },
      (error: any) =>
        dispatch(
          failure(GET_TASK_LIST_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};
export const getTask = (taskId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_TASK_BY_ID_REQUEST));

    getTaskService(taskId).then(
      (result: any) => dispatch(success(GET_TASK_BY_ID_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_TASK_BY_ID_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getChatNotificationTasks = (ids: number[]) => {
  return (dispatch: any) => {
    dispatch(request(GET_CHATNOTIFICTION_TASK_LIST_REQUEST));

    getChatNotificationTasksService(ids).then(
      (result: any) => dispatch(success(GET_CHATNOTIFICTION_TASK_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_CHATNOTIFICTION_TASK_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const updateTask = (data: addTaskValue, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_TASK_REQUEST));

    await updateTaskService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_TASK_SUCCESS, result));
        showToastMessageSuccess("Task updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(UPDATE_TASK_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const requestDueDateChange = (data: dueDateChangeValue) => {
  return async (dispatch: any) => {
    dispatch(request(DUE_DATE_CHANGE_REQUEST));

    await requestDueDateChangeService(data).then(
      (result: any) => {
        dispatch(success(DUE_DATE_CHANGE_SUCCESS, result));
        showToastMessageSuccess("Request for due date change sent to Manager/Expert");
      },
      (error: any) => {
        dispatch(
          failure(DUE_DATE_CHANGE_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

// Fetch reviewer requests
export const fetchReviewerRequests = (id: any) => {
  return async (dispatch: any) => {
    dispatch(request(FETCH_REVIEWER_REQUESTS_REQUEST));

    await getReviewerRequestsService(id).then(
      (result: any) => {
        dispatch(success(FETCH_REVIEWER_REQUESTS_SUCCESS, result));
      },
      (error: any) => {
        dispatch(
          failure(FETCH_REVIEWER_REQUESTS_FAILURE, GetErrorMessage(error?.response?.data))
        );
      }
    );
  };
};

// Update request status
export const updateRequestStatus = (data: { requestId: number; status: number, reviewerId: string }) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_REQUEST_STATUS_REQUEST));

    await updateRequestStatusService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_REQUEST_STATUS_SUCCESS, result));
        showToastMessageSuccess("Request status updated successfully.");
      },
      (error: any) => {
        dispatch(
          failure(UPDATE_REQUEST_STATUS_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

// get all requests for due date changes
export const getAllRequestForDueDateChange = () => {
  return async (dispatch: any) => {
    dispatch(request(GET_ALL_REQUEST_REQUEST));

    await getAllRequestForDueDateChangeService().then(
      (result: any) => {
        dispatch(success(GET_ALL_REQUEST_SUCCESS, result));
        //showToastMessageSuccess("");
      },
      (error: any) => {
        dispatch(
          failure(GET_ALL_REQUEST_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getAssignee = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_ASSIGNEE_REQUEST));

    getAssigneeService(searchObj).then(
      (result: any) => dispatch(success(GET_ASSIGNEE_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_ASSIGNEE_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};
export const getCreater = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_CREATER_REQUEST));

    getCreaterService(searchObj).then(
      (result: any) => dispatch(success(GET_CREATER_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_CREATER_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};
export const getAssigneedTaskToUser = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_ASSIGNED_TASK_REQUEST));

    getAssigneedTaskToUserService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_ASSIGNED_TASK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_ASSIGNED_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getPlannerTask = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_PLANNER_TASK_REQUEST));

    getPlannerTaskService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_PLANNER_TASK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_PLANNER_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getUrgentPlannerTask = (userId: string | null) => {

  return (dispatch: any) => {
    dispatch(request(GET_URGENT_TASK_REQUEST));

    getUrgentPlannerTaskService(userId).then(
      (result: any) =>
        dispatch(success(GET_URGENT_TASK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_URGENT_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getCheckingTaskToUser = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_CHECKING_TASK_REQUEST));

    getAssigneedTaskToUserService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_CHECKING_TASK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_CHECKING_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const getFollowupTaskList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_FOLLOWUP_TASK_REQUEST));

    getAssigneedTaskToUserService(searchObj).then(
      (result: any) =>
        dispatch(success(GET_FOLLOWUP_TASK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_FOLLOWUP_TASK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const updateTaskAssignee = (
  data: assigneeChangeData,
  successCallback: Function,
  failureCallback?: Function
) => {
  return (dispatch: any) => {
    dispatch(request(CHANGE_TASK_ASSIGNEE_REQUEST));

    updateTaskAssigneeService(data).then(
      (result: any) => {
        dispatch(success(CHANGE_TASK_ASSIGNEE_SUCCESS, result.data));
        showToastMessageSuccess("Assignee updated");
        successCallback && successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            CHANGE_TASK_ASSIGNEE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        failureCallback && failureCallback();
      }
    );
  };
};

export const updateTaskAssigneeFromTaskDetails = (
  data: assigneeChangeData,
  successCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(CHANGE_TASK_ASSIGNEE_REQUEST));

    updateTaskAssigneeService(data).then(
      (result: any) => {
        dispatch(success(ADD_LOG_FOR_CHANGE_TASK_ASSIGNEE, result.data));
        showToastMessageSuccess("Assignee updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            CHANGE_TASK_ASSIGNEE_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        failureCallback();
      }
    );
  };
};

export const updateTaskStatus = ({
  data,
  status,
  successCallback,
  failureCallback,
}: {
  data: statusChangeData;
  status?: string;
  successCallback?: Function;
  failureCallback?: Function;
}) => {
  return async (dispatch: any) => {
    dispatch(request(CHANGE_TASK_STATUS_REQUEST));

    await updateTaskStatusService(data).then(
      (result: any) => {
        status &&
          dispatch(
            success(CHANGE_TASK_STATUS_SUCCESS, {
              log: result.data,
              status: status,
            })
          );
        showToastMessageSuccess("Task status updated");
        successCallback && successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            CHANGE_TASK_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        failureCallback && failureCallback();
      }
    );
  };
};
export const uploadFile = (
  data: uploadFileData,
  successCallback?: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPLOAD_FILE_REQUEST));

    await uploadFileService(data).then(
      (result: any) => {
        dispatch(success(UPLOAD_FILE_SUCCESS, result.data));
        showToastMessageSuccess("File added");
        successCallback && successCallback();
      },
      (error: any) => {
        dispatch(
          failure(UPLOAD_FILE_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const deleteFile = (id: number, successCallback: Function) => {
  return (dispatch: any) => {
    dispatch(request(DELETE_FILE_REQUEST));

    deleteFileService(id).then(
      (result: any) => {
        dispatch(
          success(DELETE_FILE_SUCCESS, {
            changes: result.data.data.records,
            id: id,
          })
        );
        showToastMessageSuccess("File deleted");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(DELETE_FILE_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const addRemarks = (data: remarkData, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_REMARKS_REQUEST));

    await addRemarksService(data).then(
      (result: any) => {
        dispatch(success(ADD_REMARKS_SUCCESS, result.data));
        showToastMessageSuccess("Remark added");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(ADD_REMARKS_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const editRemarks = (data: remarkData, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(EDIT_REMARKS_REQUEST));

    await editRemarksService(data).then(
      (result: any) => {
        dispatch(success(EDIT_REMARKS_SUCCESS, result.data));
        showToastMessageSuccess("Remark updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(EDIT_REMARKS_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};
export const deleteRemarks = (id: number, successCallback?: Function) => {
  return (dispatch: any) => {
    dispatch(request(DELETE_REMARKS_REQUEST));

    deleteRemarksService(id).then(
      (result: any) => {
        dispatch(success(DELETE_REMARKS_SUCCESS, id));
        successCallback && successCallback();
        showToastMessageSuccess("Remark deleted");
      },
      (error: any) => {
        dispatch(
          failure(
            DELETE_REMARKS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const clientMessageHandler = (message: string) => {
  return (dispatch: any) => {
    dispatch(success(CLIENT_MESSAGE_HANDLER, message));
  };
};

export const clientMessageFileHandler = (file: any) => {
  return (dispatch: any) => {
    dispatch(success(CLIENT_FILE_HANDLER, file));
  };
};

export const selectAllTask = () => {
  return (dispatch: any) => {
    dispatch(success(SELECT_ALL_TASK_CHECKBOX, ""));
  };
};
export const unSelectAllTask = () => {
  return (dispatch: any) => {
    dispatch(success(UNSELECT_ALL_TASK_CHECKBOX, ""));
  };
};
export const selectTask = (taskId: number) => {
  return (dispatch: any) => {
    dispatch(success(SELECT_TASK_CHECKBOX, taskId));
  };
};
export const unSelectTask = (taskId: number) => {
  return (dispatch: any) => {
    dispatch(success(UNSELECT_TASK_CHECKBOX, taskId));
  };
};

export const uploadFileInRemarks = (
  fileData: any,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPLOAD_FILE_TO_REMARKS_REQUEST));

    await uploadFileToRemarks(fileData).then(
      (result: any) => {
        dispatch(success(UPLOAD_FILE_TO_REMARKS_SUCCESS, result));
        successCallback && successCallback({ ...result.data.data.records });
      },
      (error: any) => {
        dispatch(
          failure(
            UPLOAD_FILE_TO_REMARKS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};
export const downloadFile = (
  downloadDataObj: { id: number; destination?: string },
  fileName: string,
  callback?: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(DOWNLOAD_FILE_REQUEST));

    await downloadFileService(downloadDataObj, fileName).then(
      (result: any) => {
        dispatch(success(DOWNLOAD_FILE_SUCCESS, result));
        callback && callback();
      },
      (error: any) => {
        dispatch(
          failure(DOWNLOAD_FILE_FAILURE, GetErrorMessage(error?.response?.data))
        );
        callback && callback();
        // showToastMessageFailure(error.response.data.error.errorMessage);
      }
    );
  };
};

export const changeStatusOfActivity = (
  activityData: activityData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(CHANGE_TASK_ACTIVITY_STATUS_REQUEST));

    try {
      const result = await changeStatusOfActivityService(activityData);
      dispatch(success(CHANGE_TASK_ACTIVITY_STATUS_SUCCESS, result.data));
      successCallback();
      return result;
    } catch (error: any) {
      dispatch(
        failure(
          CHANGE_TASK_ACTIVITY_STATUS_FAILURE,
          GetErrorMessage(error?.response?.data)
        )
      );
    }

    // await changeStatusOfActivityService(activityData).then(
    //   (result: any) => {
    //     result = result;
    //     dispatch(success(CHANGE_TASK_ACTIVITY_STATUS_SUCCESS, result.data));
    //     successCallback();
    //     sentForCheckWhenAllChecked(result.data.data.records.Activities);
    //   },
    //   (error: any) => {
    //     dispatch(
    //       failure(
    //         CHANGE_TASK_ACTIVITY_STATUS_FAILURE,
    //         GetErrorMessage(error?.response?.data)
    //       )
    //     );
    //   }
    // );
  };
};

export const taskAddForChecking = (sentData: sentForCheckDataModal) => {
  return async (dispatch: any) => {
    dispatch(request(TASK_ADD_FOR_CHECKING_REQUEST));

    await taskForCheckingService(sentData).then(
      (result: any) => {
        dispatch(success(TASK_ADD_FOR_CHECKING_SUCCESS, result.data));
      },
      (error: any) => {
        dispatch(
          failure(
            TASK_ADD_FOR_CHECKING_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};

export const generalFileUpload = (file: any, successCallback: any) => {
  return async (dispatch: any) => {
    dispatch(request(GENERAL_FILE_UPLOAD_REQUEST));

    await generalfileUploadService(file).then(
      (result: any) => {
        dispatch(success(GENERAL_FILE_UPLOAD_SUCCESS, result.data));
        successCallback(result.data.data.records);
      },
      (error: any) => {
        dispatch(
          failure(
            GENERAL_FILE_UPLOAD_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
      }
    );
  };
};

export const sendOTPRequest = (
  taskId: number,
  successCallback: Function,
  failureCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(SEND_OTP_REQUEST));

    await sendOTPRequestService(taskId).then(
      (result: any) => {
        dispatch(success(SEND_OTP_SUCCESS, result.data));
        successCallback();
      },
      (error: any) => {
        dispatch(failure(SEND_OTP_FAILURE, error?.response?.data));
        showToastMessageFailure(
          error?.response?.data || "Something went wrong"
        );
        failureCallback();
      }
    );
  };
};

export const updateBillStatus = (
  taskId: Array<number>,
  billStatus: number,
  successCallback: Function,
  failureCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(CHANGE_BILL_STATUS_REQUEST));

    await updateBillStatusService(taskId, billStatus).then(
      (result: any) => {
        dispatch(success(CHANGE_BILL_STATUS_SUCCESS, { billStatus, taskId }));
        showToastMessageSuccess("Bill status updated");
        successCallback();
      },
      (error: any) => {
        dispatch(
          failure(
            CHANGE_BILL_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        failureCallback();
      }
    );
  };
};

export const updateUrgentStatus = (
  data :any, 
  successCallback: Function,
  failureCallback: Function) =>{
  return (dispatch: any) => {
    dispatch(request(SET_URGENT_STATUS_REQUEST));

    updateUrgentStatusService(data).then(
      (result: any) => {
        dispatch(success(SET_URGENT_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Task Apply Urgent Status");
        successCallback();
      },
      (error: any) =>{
        dispatch(
          failure(
            SET_URGENT_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        failureCallback();
      }
    );
  };
}

export const getTaskReportList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_TASK_REPORT_LIST_REQUEST));

    getTaskReportListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_TASK_REPORT_LIST_SUCCESS, result.data));
      },
      (error: any) =>
        dispatch(
          failure(
            GET_TASK_REPORT_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getCompletedTaskReportList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_COMPLETED_TASK_REPORT_LIST_REQUEST));

    getCompletedTaskReportListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_COMPLETED_TASK_REPORT_LIST_SUCCESS, result.data));
      },
      (error: any) =>
        dispatch(
          failure(
            GET_COMPLETED_TASK_REPORT_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getBillingPendingTaskList = (searchObj: searchParams) => {
  return (dispatch: any) => {
    dispatch(request(GET_BILLING_PENDING_TASK_LIST_REQUEST));

    getBillingPendingTaskListService(searchObj).then(
      (result: any) => {
        dispatch(success(GET_BILLING_PENDING_TASK_LIST_SUCCESS, result.data));
        dispatch(success(ADD_PARENT_DATA_FOR_SELECTED_LIST, result.data));
      },
      (error: any) =>
        dispatch(
          failure(
            GET_BILLING_PENDING_TASK_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
